<template>
  <div class="btn-container">
    <el-button
      v-if="[1, 2, 3].includes(jobData.status) && hasPermission($permissionFunctionCode.EDIT) && (hasSysAdminPermission || jobData.mitigatingCompanyOrgId === currentCompany.id)"
      @click="cancelJop"
      type="danger"
      size="small"
      round
    >
      Cancel Job
    </el-button>
    <!--提交为待确认状态-->
    <el-button
      v-if="![3,4,5].includes(jobData.status) &&
      hasPermission($permissionFunctionCode.ADD) && canEdit"
      style="min-width: 100px"
      type="primary"
      size="small"
      round
      :loading="loading"
      @click="$emit('submit')">
      Submit
    </el-button>
    <el-button
      v-if="jobData.status==4 && canEdit"
      style="min-width: 100px"
      type="primary"
      size="small"
      round
      :loading="loading"
      @click="$emit('submitReferenceNo')">
      Submit Reference No
    </el-button>
    <!--关闭当前页-->
    <el-button
      style="min-width: 100px"
      outline
      size="small"
      round
      @click="$emit('close', noAction = true)">
      Close
    </el-button>
    <!-- <el-dropdown
      v-if="showCancelButton() && hasPermission($permissionFunctionCode.EDIT)">
      <el-button
        style="min-width: 80px;margin-left: 10px"
        size="small"
        round>
        <em class="el-icon-more"></em>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          @click.native="cancelJop">
          Cancel Job
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'

export default {
  name: 'ScheduleOperationBar',
  mixins: [baseMixin],
  props: {
    hasSysAdminPermission: Boolean,
    jobData: {
      type: Object,
      default: () => ({}),
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      currentCompany: 'currentCompany'
    })
  },
  methods: {
    cancelJop () {
      this.$confirm('', 'Confirm cancel?', {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
        center: true,
        roundButton: true
      }).then(() => {
        const url = `ebdn/jobs/${this.jobData.id}/cancel`
        this.$request.put({
          url
        }).then(res => {
          if (res?.code === 1000) {
            this.$emit('update:jobData', { ...this.jobData, status: 5 })
            this.$message.success('Job cancelled successfully')
            this.$emit('close')
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-container {
  text-align: right;
  margin-bottom: 10px;
  margin-right: 20px;
}
</style>
